import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { SpinLoading } from "antd-mobile";

const Home = lazy(() => import("@/pages/home"));
const Search = lazy(() => import("@/pages/search"));
const Product = lazy(() => import("@/pages/product"));
const ChapterMenu = lazy(() => import("@/pages/chapterMenu"));
const Chapter = lazy(() => import("@/pages/chapter"));
const UserAgreement = lazy(() => import("@/pages/agreement/user"));
const PersonalAgreement = lazy(() => import("@/pages/agreement/personal"));
const AuthorAgreement = lazy(() => import("@/pages/agreement/author"));
const Login = lazy(() => import("@/pages/login"));
//Do not edit! CDA PREPEND:import

const routerList = [
  createRedirect("/", "/home"),
  {
    path: "/home",
    element: createSuspense(<Home />)
  },
  {
    path: "/login",
    element: createSuspense(<Login />)
  },
  {
    path: "/userAgreement",
    element: createSuspense(<UserAgreement />)
  },
  {
    path: "/personalAgreement",
    element: createSuspense(<PersonalAgreement />)
  },
  {
    path: "/authorAgreement",
    element: createSuspense(<AuthorAgreement />)
  },
  {
    path: "/search",
    element: createSuspense(<Search />)
  },
  {
    path: "/product/:id",
    element: createSuspense(<Product />)
  },
  {
    path: "/chapterMenu/:id",
    element: createSuspense(<ChapterMenu />)
  },
  {
    path: "/chapter/:id",
    element: createSuspense(<Chapter />)
  }
  //Do not edit! CDA PREPEND:router
  // {
  //   path: '*',
  //   element: createSuspense(<Page404 />),
  // },
];

function SuspenseElement({ children }) {
  return (
    <Suspense
      fallback={
        <div
          style={{ height: "300px", lineHeight: "300px", textAlign: "center" }}
        >
          <SpinLoading size="large"></SpinLoading>
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

function createSuspense(children) {
  return <SuspenseElement>{children}</SuspenseElement>;
}

function createRedirect(path, to, replace = true) {
  return {
    path,
    element: <Navigate replace={replace} to={to} />
  };
}

const Router = () => useRoutes(routerList);

export default Router;

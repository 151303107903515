export const noop = () => {};
export const emptyObj = {};
export const emptyArr = [];

export const PAGE_CONTAINER_ID = "PAGE_CONTAINER_ID";

export const cdnPath = "https://imepo-1310028400.file.myqcloud.com/";
export const minLineHeight = 20;
export const minFontSize = 12;
export const colorConfigArray = [
  {
    background: "#ffffff",
    btnBackground: "#ffffff",
    btnBorderColor: "#1EBF6E",
    btnColor: "#1EBF6E"
  },
  {
    background: "#EAE2CE",
    btnBackground: "#C7BCA1",
    btnBorderColor: "#C7BCA1",
    btnColor: "#FFFFFF"
  },
  {
    background: "#D4E3D0",
    btnBackground: "#1EBF6E",
    btnBorderColor: "#1EBF6E",
    btnColor: "#ffffff"
  }
];
export const blackColorConfig = {
  background: "#242424",
  btnBackground: "#1EBF6E",
  btnBorderColor: "#1EBF6E",
  btnColor: "#ffffff"
};
export const darkModeColor = "#8C8C8C";

export const coverCutReg = "imageMogr2/crop/450x600/gravity/center";

import { useReducer } from 'react';
import update from 'immutability-helper';
import { createContainer } from 'unstated-next';

const initialState = {
  user: '',
  themeColor: '#2A79F1', // 初始值需要和theme.variable.less中保持一致
  gameList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set-user':
      return update(state, {
        user: { $set: action.payload },
      });
    case 'set-theme-color':
      return update(state, { themeColor: { $set: action.payload } });
    case 'set-game-list':
      return update(state, { gameList: { $set: action.payload } });
  }
  return state;
};

function useGlobalState() {
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );
  return {
    store: state,
    dispatch,
  };
}

const GlobalState = createContainer(useGlobalState);


export default GlobalState;
